import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import moment from "moment";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import DfContent from "@/vue/domain/content/df-content";

@Component({
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
    },
  },
})
export default class DfPromotionCardComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: () => ({}) })
  promotion!: DfPromotion;

  get flyer(): Array<DfContent> {
    return this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "FLY" && content.promotion == this.promotion.identifier)[0];
  }

  get promotionDateStart(): string {
    return this.promotion ? this.$options.filters!.dateFormat(this.promotion.startDate) : null;
  }

  get promotionDateEnd(): string {
    return this.promotion ? this.$options.filters!.dateFormat(this.promotion.endDate) : null;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  private onClick() {
    if (this.pdf) {
      window.open(this.pdf, "_blank");
    } else if (this.url) {
      window.open(this.url, "_blank");
    } else {
      Utils.setAnaliticsTraker({ event: "click_promo", store: this.currentStore.name, promo_name: this.promotion.description });
      this.$router.push({ name: "promotion", params: { storeAlias: this.currentStore.alias, promotionAlias: this.promotion.alias } });
    }
  }

  get promotionPreview(): string {
    const promotionPreview: string = Utils.getPropertyImageUrl(this.promotion, Utils.PROPERTY_PREVIEW, null);
    return promotionPreview ? `url('${promotionPreview}')` : null;
  }

  get flyerPreview(): string {
    const flyerPreview: string = Utils.getPropertyImageUrl(this.flyer, Utils.PROPERTY_PREVIEW, null);
    return flyerPreview ? `url('${flyerPreview}')` : null;
  }

  get preview(): string {
    if (this.flyerPreview != null) {
      return this.flyerPreview;
    }
    if (this.promotionPreview != null) {
      return this.promotionPreview;
    }
    return "";
  }

  get pdf(): string {
    return Utils.getPropertyImageUrl(this.promotion, Utils.PROPERTY_PDF, null);
  }

  get url(): string {
    return Utils.getPropertyValue(this.promotion, Utils.PROPERTY_URL, "STRING");
  }
}
